import React, { useState, useEffect } from "react";

const Header = ({ camara }) => {
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://agropost.fazendamandaguari.com.br/getcoldroomivd/update?camara=${encodeURIComponent(camara)}`
        );

        if (!response.ok) {
          throw new Error(`Erro na requisição: ${response.statusText}`);
        }

        const data = await response.json();
        setDados(data);
        console.log("dados recebidos", dados)
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [camara]);

  // Função para calcular a diferença de dias entre duas datas
  const calcularDias = (dataInicio, dataFim) => {
    const inicio = new Date(dataInicio);
    const fim = new Date(dataFim);
    const diffTime = Math.abs(fim - inicio);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Converte para dias
  };

  // Função para calcular os dias em cada fase
  const calcularDiasFase = (faseNome) => {
    // Filtra os dados para a fase específica
    const fase = dados.filter(item => item.fase === faseNome);
    console.log("fase atual", fase)

    if (fase.length === 0) return 0; // Se não encontrar a fase, retorna 0

    // Ordenar as datas em ordem crescente
    const datas = fase.map(item => new Date(item.data));
    const dataInicio = Math.min(...datas); // Pega a menor data
    const dataFim = Math.max(...datas); // Pega a maior data

    // Calcular a diferença de dias entre a data mais recente e a data mais antiga
    return calcularDias(dataInicio, dataFim);
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>Erro ao carregar os dados: {error}</div>;
  }

  // Filtra os dados para pegar apenas as entradas da câmara sendo acessada
  const entradaFiltrada = dados.find((entrada) => entrada.camara === camara);

  if (!entradaFiltrada) {
    return <div>Não há dados disponíveis para esta câmara.</div>;
  }

  // Calcular os dias em cada fase
  const diasVernalizacao = calcularDiasFase("Vernalização(-1,5 a -3 graus)");
  const diasRepouso = calcularDiasFase("Repouso(22 a 25 graus)");
  const diasPreCamara = calcularDiasFase("Pré-Câmara(13 a 15 graus)");
  const diasAlhoDebulhado = calcularDiasFase("Alho debulhado (2 a 0°C)");


  return (
    <div>
      <h1><strong>{entradaFiltrada.camara}</strong></h1>

      <table border="1" style={{ borderCollapse: "collapse", width: "100%", textAlign: "left" }}>
        <thead>
          <tr>
            <th>Lote</th>
            <th>Descrição</th>
            <th>Data de Entrada</th>
          </tr>
        </thead>
        <tbody>
          {entradaFiltrada.camara === "Câmara 2" && (
            <tr>
              <td>1</td>
              <td>Sekita</td>
              <td>-</td>
            </tr>
          )}
          {entradaFiltrada.camara === "Câmara 3" && (
            <tr>
              <td>2</td>
              <td>Shimada</td>
              <td>-</td>
            </tr>
          )}
          {entradaFiltrada.camara === "Câmara 8" && (
            <tr>
              <td>3</td>
              <td>Mandaguari Estaleiro 1</td>
              <td>23/11/2024</td>
            </tr>
          )}
          {entradaFiltrada.camara === "Câmara 7" && (
            <tr>
              <td>4</td>
              <td>Mandaguari Estaleiro 2</td>
              <td>26/11/2024</td>
            </tr>
          )}
          {entradaFiltrada.camara === "Câmara 4" && (
            <tr>
              <td>5</td>
              <td>Mandaguari Lera</td>
              <td>03/12/2024</td>
            </tr>
          )}
          {entradaFiltrada.camara === "Câmara 6" && (
            <tr>
              <td>6</td>
              <td>Consumo</td>
              <td>21/11/2024</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Exibe os dias em cada fase */}
      <table border="1" style={{ borderCollapse: "collapse", width: "100%", marginTop: "20px", textAlign: "left" }}>
        <thead>
          <tr>
            <th>Fase</th>
            <th>Dias</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Repouso (22 a 25 graus)</td>
            <td>{diasRepouso} dias</td>
          </tr>
          <tr>
            <td>Vernalização (-1,5 a -3 graus)</td>
            <td>{diasVernalizacao} dias</td>
          </tr>
          <tr>
            <td>Pré-Câmara (13 a 15 graus)</td>
            <td>{diasPreCamara} dias</td>
          </tr>
          <tr>
            <td>Alho Debulhado (2 a 0°C)</td>
            <td>{diasAlhoDebulhado} dias</td>
          </tr>
        </tbody>
      </table>
    </div>
  );

};

export default Header;

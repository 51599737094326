import { Route, BrowserRouter, Routes } from "react-router-dom";
import DadosColdroom from "../pages/ColdRoom/DadosColdroom.js";
import Ivd from "../pages/Ivd/ivd.js";
import Operations from "../pages/Operations/operations"

const RoutesComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DadosColdroom />} />
        <Route path="/ivd/:camara" element={<Ivd />} /> {/* Rota com parâmetro */}
        <Route path="/operations/:camara" element={<Operations />} /> {/* Rota com parâmetro */}
      </Routes>
    </BrowserRouter>
  );
}

export default RoutesComponent;

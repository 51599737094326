import React, { useEffect, useState } from "react";
import { IoWaterOutline, IoSnowOutline } from "react-icons/io5";
import { LuThermometerSnowflake } from "react-icons/lu";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import { useNavigate } from 'react-router-dom';

const fases = [
  {
    label: "Pré-Câmara(13 a 15 graus)",
    value: "Pré-Câmara(13 a 15 graus)",
    range: [13, 15],
  },
  {
    label: "Repouso(22 a 25 graus)",
    value: "Repouso(22 a 25 graus)",
    range: [22, 25],
  },
  {
    label: "Vernalização(-1,5 a -3 graus)",
    value: "Vernalização(-1,5 a -3 graus)",
    range: [-3, -1.5],
  },
  {
    label: "Alho debulhado (2 a 0°C)",
    value: "Alho debulhado (2 a 0°C)",
    range: [0, 2],
  },
];

const DadosColdroom = () => {
  const [dados, setDados] = useState([]);
  const [dadosGrafico, setDadosGrafico] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expanded, setExpanded] = useState([]); // Controla qual box está expandida
  const [selectedCamaraData, setSelectedCamaraData] = useState([]); // Armazena os dados da câmara selecionada

  const navigate = useNavigate();

  const ordemFixa = [
    "Anti-Câmara",
    "Câmara 1",
    "Câmara 2",
    "Câmara 3",
    "Câmara 4",
    "Câmara 5",
    "Câmara 6",
    "Câmara 7",
    "Câmara 8",
  ];

  useEffect(() => {
    fetch("https://agropost.fazendamandaguari.com.br/getcoldroom/update")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Erro na requisição: ${response.statusText}`);
        }
        return response.json();
      })
      .then((data) => {
        // Calcula a data limite das últimas 100 horas
        const now = new Date();
        const limitDate = new Date(now.getTime() - 48 * 60 * 60 * 1000);

        // Filtra os dados para pegar apenas os das últimas 100 horas
        const filteredData = data.filter((item) => {
          const itemDate = new Date(item.data);
          return itemDate >= limitDate;
        });
        const sortedData = filteredData.sort((a, b) => new Date(b.data) - new Date(a.data));


        // Remove duplicados baseado no campo 'camara', mantendo o mais recente
        const uniqueData = filteredData.reduce((acc, current) => {
          if (!acc.some((item) => item.camara === current.camara)) {
            acc.push(current);
          }
          return acc;
        }, []);

        // Mantém a ordem fixa das câmaras
        const orderedData = uniqueData.sort((a, b) => {
          const indexA = ordemFixa.indexOf(a.camara);
          const indexB = ordemFixa.indexOf(b.camara);
          return indexA - indexB;
        });

        setDados(orderedData);
        setDadosGrafico(filteredData);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  const toggleExpand = (id, camara) => {
    if (expanded.includes(id)) {
      // Se a câmara já estiver expandida, remova-a da lista
      setExpanded(expanded.filter(expandedId => expandedId !== id));
    } else {
      // Adiciona a nova câmara à lista de expandidas
      setExpanded([...expanded, id]);
      const camaraData = dadosGrafico.filter(item => item.camara === camara);
      setSelectedCamaraData(camaraData);
    }
  };

  const getUmidadeStatus = (umidade) => {
    if (umidade >= 60 && umidade <= 70) {
      return "green"; // Dentro da faixa (60% a 70%)
    } else if (umidade > 70 && umidade <= 75) {
      return "yellow";
    } else if (umidade >= 55 && umidade < 60) {
      return "yellow";
    } else {
      return "red"; // Fora da faixa
    }
  };

  const getMedCo2Status = (medCo2) => {
    if (medCo2 > 1200 && medCo2 <= 1500) {
      return { icon: <IoSnowOutline className="icon" />, color: "yellow" }; // Fora do limite
    } else if (medCo2 > 1500) {
      return { icon: <IoSnowOutline className="icon" />, color: "red" }; // Fora do limite
    }
    return { icon: <IoSnowOutline className="icon" />, color: "green" }; // Dentro do limite
  };

  const getTemperaturaStatus = (fase, temperatura) => {
    const [min, max] = fase.range;

    if (temperatura < min || temperatura > max) {
      return { icon: <LuThermometerSnowflake className="icon" />, color: "red" }; // Fora da faixa
    } else {
      return { icon: <LuThermometerSnowflake className="icon" />, color: "green" }; // Dentro da faixa
    }
  };

  // Função para gerar dados para o gráfico de linha usando todos os dados da câmara selecionada
  const generateTemperatureData = (coldroomData) => {
    return coldroomData.map((entry) => {
      const date = new Date(entry.data);
      return {
        name: date.toLocaleTimeString('pt-BR', {
          day: '2-digit',   // Dia com dois dígitos
          month: '2-digit', // Mês com dois dígitos
          year: 'numeric',  // Ano com quatro dígitos
          hour: '2-digit',  // Hora com dois dígitos
          minute: '2-digit', // Minutos com dois dígitos
        }), // Usamos a hora como a chave para o gráfico
        temperatura: entry.temperatura,

      };
    });
  };

  // Função para gerar dados para o gráfico de linha usando todos os dados da câmara selecionada
  const generateUmidadeData = (coldroomData) => {
    return coldroomData.map((entry) => {
      const date = new Date(entry.data);
      return {
        name: date.toLocaleTimeString('pt-BR', {
          day: '2-digit',   // Dia com dois dígitos
          month: '2-digit', // Mês com dois dígitos
          year: 'numeric',  // Ano com quatro dígitos
          hour: '2-digit',  // Hora com dois dígitos
          minute: '2-digit', // Minutos com dois dígitos
        }), // Usamos a hora como a chave para o gráfico
        umidade: entry.umidade,
        desumidificacao: entry.desumidificacao,
        renovacao: entry.renovacao
      };
    });
  };

  const generateCo2Data = (coldroomData) => {
    return coldroomData.map((entry) => {
      const date = new Date(entry.data);
      return {
        name: date.toLocaleTimeString('pt-BR', {
          day: '2-digit',   // Dia com dois dígitos
          month: '2-digit', // Mês com dois dígitos
          year: 'numeric',  // Ano com quatro dígitos
          hour: '2-digit',  // Hora com dois dígitos
          minute: '2-digit', // Minutos com dois dígitos
        }), // Usamos a hora como a chave para o gráfico
        medCo2: entry.medCo2,
      };
    });
  };

  if (loading) {
    return <div className="loading">Carregando...</div>;
  }

  if (error) {
    return <div className="error">Erro: {error}</div>;
  }

  return (
    <div className="container">
      {dados.map((item) => {
        const fase = fases.find((fase) => item.fase === fase.label);
        if (!fase) return null;

        const { icon: tempIcon, color: tempColor } = getTemperaturaStatus(fase, item.temperatura);
        const umidadeStatus = getUmidadeStatus(item.umidade);
        const medCo2Status = getMedCo2Status(item.medCo2);

        return (
          <div
            key={item.id_coldroom}
            className="coldroom-box"
            onClick={() => toggleExpand(item.id_coldroom, item.camara)}
          >
            <div className="header">
              <h3>{item.camara}</h3>
              <div className="container-buttons">
                <button onClick={() => navigate(`/ivd/${item.camara}`)}>
                  IVD
                </button>
                <button onClick={() => navigate(`/operations/${item.camara}`)}>
                  OP
                </button>

              </div>

            </div>
            <div className="coldroom-header">
              <div className="status">
                <strong>Status:</strong> {item.status}
              </div>
              <div className="status">
                <strong>Fase:</strong> {item.fase}
              </div>

            </div>
            <div className="details-icons">
              <div className={`coldroom-item ${tempColor}`}>
                {tempIcon}
                <div className="value">{item.temperatura}°C</div>
              </div>
              <div className={`coldroom-item ${umidadeStatus}`}>
                <IoWaterOutline className="icon" style={{
                  color: umidadeStatus === "red"
                    ? "red"
                    : umidadeStatus === "green"
                      ? "green"
                      : "rgb(255, 136, 0);"
                }} />
                <div className="value">{item.umidade}%</div>
              </div>
              <div className={`coldroom-item ${medCo2Status.color}`}>
                {medCo2Status.icon}
                <div className="value">{item.medCo2 + 'ppm' || "0ppm"}</div>
              </div>
            </div>

            <div className="ultima-analise">
              <strong>Última Análise:</strong>
              {item.data ? new Date(item.data).toLocaleString('pt-BR') : "N/A"}
            </div>
            {/* Renderização condicional para os lotes */}
            {item.camara === "Câmara 2" && (
              <div className="ultima-analise">
                <strong>Lotes: </strong>1 - Sekita
              </div>
            )}
            {item.camara === "Câmara 3" && (
              <div className="ultima-analise">
                <strong>Lotes: </strong>2 - Shimada
              </div>
            )}
            {item.camara === "Câmara 8" && (
              <div className="ultima-analise">
                <strong>Lotes: </strong>3 - Mandaguari Estaleiro 1
              </div>
            )}
            {item.camara === "Câmara 7" && (
              <div className="ultima-analise">
                <strong>Lotes: </strong>4 - Mandaguari Estaleiro 2
              </div>
            )}
            {item.camara === "Câmara 4" && (
              <div className="ultima-analise">
                <strong>Lotes: </strong>5 - Mandaguari Lera
              </div>
            )}
            {item.camara === "Câmara 6" && (
              <div className="ultima-analise">
                <strong>Lotes: </strong>6 - Consumo
              </div>
            )}

            {item.renovacao && (
              <div className="status">
                <strong>Renovação:</strong> {item.renovacao} Minutos
              </div>
            )}

            {item.desumidificacao && (
              <div className="status">
                <strong>Desumidificacão:</strong> {item.desumidificacao} Minutos
              </div>
            )}
            {item.obs && (
              <div className="ultima-analise">
                <strong>Observação: </strong>
                {item.obs ? item.obs : "N/A"}
              </div>
            )}

            {/* Renderiza o gráfico de linha se o box estiver expandido */}
            {expanded.includes(item.id_coldroom) && selectedCamaraData.length > 0 && (
              <>
                <ResponsiveContainer className="ivd-grafico" width="100%" height={300}>
                  <LineChart data={generateTemperatureData(selectedCamaraData)}>
                    <text
                      x="50%"
                      y="10"
                      textAnchor="middle"
                      dominantBaseline="middle"
                      style={{ fontSize: 16, fontWeight: 'bold' }}
                    >
                      Temperatura
                    </text>
                    <XAxis dataKey="name" reversed={true} />
                    <YAxis
                      type="number"
                      domain={[-10, 30]} // Ajusta dinamicamente com base nos dados
                    />
                    <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                    <Tooltip />
                    <Line type="monotone" dataKey="temperatura" stroke="#8884d8" />
                    <Line
                      type="monotone"
                      dataKey="temperatura"
                      stroke="#8884d8"
                      dot={{ r: 5 }} // Define o tamanho das bolinhas
                      label={({ x, y, value }) => (
                        <text
                          x={x}
                          y={y + 18}
                          fill="#8884d8"
                          textAnchor="middle"
                          fontSize={10}
                        >
                          {value}°C
                        </text>
                      )}
                    />


                  </LineChart>
                </ResponsiveContainer>

                <ResponsiveContainer className="ivd-grafico" width="100%" height={300}>
                  <LineChart data={generateUmidadeData(selectedCamaraData)}>
                    <text
                      x="50%"
                      y="10"
                      textAnchor="middle"
                      dominantBaseline="middle"
                      style={{ fontSize: 16, fontWeight: 'bold' }}
                    >
                      Umidade
                    </text>
                    <XAxis dataKey="name" reversed={true} />
                    <YAxis
                      type="number"
                      domain={[0, 100]}
                    />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="umidade"
                      stroke="#8884d8"
                      dot={{ r: 5 }} // Define o tamanho das bolinhas
                      label={({ x, y, value }) => (
                        <text
                          x={x}
                          y={y + 18}
                          fill="#8884d8"
                          textAnchor="middle"
                          fontSize={12}
                        >
                          {value}%
                        </text>
                      )}
                    />

                    <Line type="monotone" dataKey="umidade" stroke="#8884d8" />
                  </LineChart>

                </ResponsiveContainer>
                <ResponsiveContainer className="ivd-grafico" width="100%" height={300}>
                  <LineChart data={generateCo2Data(selectedCamaraData)}>
                    <text
                      x="50%"
                      y="10"
                      textAnchor="middle"
                      dominantBaseline="middle"
                      style={{ fontSize: 16, fontWeight: 'bold' }}
                    >
                      Co2
                    </text>
                    <XAxis dataKey="name" reversed={true} />
                    <YAxis
                      type="number"
                      domain={[0, 6000]} />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="medCo2"
                      stroke="#8884d8"
                      dot={{ r: 5 }} // Define o tamanho das bolinhas
                      label={({ x, y, value }) => (
                        <text
                          x={x}
                          y={y + 18}
                          fill="#8884d8"
                          textAnchor="middle"
                          fontSize={12}
                        >
                          {value} PPM
                        </text>
                      )}
                    />

                    <Line type="monotone" dataKey="medCo2" stroke="#8884d8" />
                  </LineChart>

                </ResponsiveContainer>
              </>
            )}
          </div>
        );
      })}
      
    </div>
  );
};

export default DadosColdroom;

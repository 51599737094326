import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';

const Operations = () => {
    const { camara } = useParams(); // Captura o parâmetro 'camara' da URL
    const [dados, setDados] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filteredData, setFilteredData] = useState([]); // Dados filtrados
    const [filterDate, setFilterDate] = useState(() => {
        const today = new Date();
        return today.toISOString().split("T")[0]; // Formato yyyy-MM-dd
    });


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `https://agropost.fazendamandaguari.com.br/getoperationscoldroom/update?camara=${encodeURIComponent(camara)}`
                );

                if (!response.ok) {
                    throw new Error(`Erro na requisição: ${response.statusText}`);
                }

                const data = await response.json();
                console.log("Dados recebidos:", data);
                setDados(data);
            } catch (err) {
                console.error(err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [camara]);

    const genereteOperations = (operationsData) => {
        return operationsData.map((entry) => {
            const date = new Date(entry.Data);
            return {
                name: date.toLocaleTimeString('pt-BR', {
                    day: '2-digit',   // Dia com dois dígitos
                    month: '2-digit', // Mês com dois dígitos
                    year: 'numeric',  // Ano com quatro dígitos
                    hour: '2-digit',  // Hora com dois dígitos
                    minute: '2-digit', // Minutos com dois dígitos
                }),
                renovacao: entry.renovacao,
                desumidificacao: entry.desumidificacao,
            };
        });
    };
    const formatData = (data) => {
        return data.map((entry) => {
            const date = new Date(entry.Data);
            return {
                ...entry,
                formattedDate: date.toLocaleDateString("pt-BR"),
                formattedTime: date.toLocaleTimeString("pt-BR"),
            };
        });
    };

    if (loading) return <div>Carregando...</div>;
    if (error) return <div>Erro: {error}</div>;

    return (
        <div className="container-table">
            <div>
                <h1>Historico de Operações</h1>
                <table>
                    <thead>
                        <tr>
                            <th>Data</th>
                            <th>Hora</th>
                            <th>Desumidificacão</th>
                            <th>Renovação</th>
                            <th>Temperatura_Anterior</th>
                            <th>Temperatura_Após</th>
                            <th>Umidade_Anterior</th>
                            <th>Umidade_Após</th>
                            <th>Co2_Anterior</th>
                            <th>Co2_Após</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(dados) && dados.length > 0 ? (
                            formatData(dados).map((entry, index) => (
                                <tr key={index}>
                                    <td>{entry.formattedDate}</td>
                                    <td>{entry.formattedTime}</td>
                                    <td>{entry.Desumidificacao ? entry.Desumidificacao : 0}</td>
                                    <td>{entry.Renovacao ? entry.Renovacao : 0}</td>
                                    <td>{entry.Temperatura_Anterior}</td>
                                    <td>{entry.Temperatura_Atual}</td>
                                    <td>{entry.Umidade_Anterior}</td>
                                    <td>{entry.Umidade_Atual}</td>
                                    <td>{entry.MedCo2_Anterior}</td>
                                    <td>{entry.MedCo2_Atual}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="10">Nenhum dado encontrado</td>
                            </tr>
                        )}
                    </tbody>

                </table>
            </div>

        </div>
    );
};

export default Operations;

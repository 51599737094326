import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import Header from "../../Components/header";

const Ivd = () => {
  const { camara } = useParams(); // Captura o parâmetro 'camara' da URL
  const [dados, setDados] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [filterDate, setFilterDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0]; // Formato yyyy-MM-dd
  });

  const [arrayDente, setArrayDente] = useState([]);
  const [arrayFolha, setArrayFolha] = useState([]);
  const [arrayIvd, setArrayIvd] = useState([]);
  const [photo, setPhoto] = useState(""); // Estado para armazenar a foto do dia filtrado

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://agropost.fazendamandaguari.com.br/getivd/update?camara=${encodeURIComponent(
            camara
          )}`
        );

        if (!response.ok) {
          throw new Error(`Erro na requisição: ${response.statusText}`);
        }

        const data = await response.json();
        console.log("Dados recebidos:", data);
        setDados(data);
        setFilteredData(data); // Inicializa com todos os dados
      } catch (err) {
        console.error(err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [camara]);

  useEffect(() => {
    if (dados.length > 0) {
      const filtered = dados.filter((entry) => {
        const entryDate = new Date(entry.dt_ivd).toISOString().split("T")[0];
        return entryDate === filterDate;
      });

      console.log("Dados filtrados:", filtered); // Verifica os dados filtrados

      setFilteredData(filtered.length > 0 ? filtered : []);

      // Encontrar a foto correspondente ao dia filtrado
      const photoForDate = filtered.length > 0 ? filtered[0].photo : null;
      console.log("Foto para a data selecionada:", photoForDate); // Verifica qual foto foi associada
      setPhoto(photoForDate); // Atualiza a foto
    } else {
      setFilteredData([]);
      setPhoto(""); // Se não houver dados, não há foto
    }
  }, [dados, filterDate]);

  useEffect(() => {
    if (dados.length > 0) {
      const denteValuesByDate = {};
      const folhaValuesByDate = {};
      const ivdValuesByDate = {};

      dados.forEach((entry) => {
        const entryDate = new Date(entry.dt_ivd).toISOString().split("T")[0];

        if (!denteValuesByDate[entryDate]) {
          denteValuesByDate[entryDate] = [];
          folhaValuesByDate[entryDate] = [];
          ivdValuesByDate[entryDate] = [];
        }

        if (entry.diametros && entry.diametros.length > 0) {
          entry.diametros.forEach((diametro) => {
            const tamanhoDente = parseFloat(diametro.tamanho_dente);
            const tamanhoFolha = parseFloat(diametro.tamanho_folha);
            const ivd = (tamanhoFolha / tamanhoDente) * 100;

            denteValuesByDate[entryDate].push(tamanhoDente);
            folhaValuesByDate[entryDate].push(tamanhoFolha);
            ivdValuesByDate[entryDate].push(ivd);
          });
        }
      });

      setArrayDente(denteValuesByDate);
      setArrayFolha(folhaValuesByDate);
      setArrayIvd(ivdValuesByDate);
    }
  }, [dados]);

  const calculateStatistics = (values) => {
    const sum = values.reduce((acc, val) => acc + val, 0);
    const avg = values.length > 0 ? sum / values.length : 0;
    const max = Math.max(...values);
    const min = Math.min(...values);
    return { avg, max, min };
  };

  const ivdStatsByDate = Object.keys(arrayIvd)
    .sort() // Ordena as datas em ordem crescente
    .map((date, index, dates) => {
      const ivdData = arrayIvd[date];
      const ivdStats = calculateStatistics(ivdData);

      const ultimaMedia = ivdStats.avg;
      const penultimaMedia =
        index > 0 ? calculateStatistics(arrayIvd[dates[index - 1]]).avg : null;

      // Calcula o número de dias entre as datas
      const currentDate = new Date(date);
      const previousDate = index > 0 ? new Date(dates[index - 1]) : null;
      const daysDifference =
        previousDate !== null
          ? (currentDate - previousDate) / (1000 * 60 * 60 * 24)
          : null;

      return {
        date,
        media: ivdStats.avg.toFixed(2), // Média com 2 casas decimais
        min: ivdStats.min.toFixed(2), // Mínimo com 2 casas decimais
        max: ivdStats.max.toFixed(2),
        cresc:
          penultimaMedia !== null && daysDifference > 0
            ? ((ultimaMedia - penultimaMedia) / daysDifference).toFixed(2)
            : null,
      };
    });

  const generateIvdForChart = () => {
    return ivdStatsByDate.map((stat) => ({
      name: stat.date, // Exibe a data como nome
      media: stat.media,
      min: stat.min,
      max: stat.max,
      crescimento: stat.cresc,
    }));
  };

  if (loading) return <div>Carregando...</div>;
  if (error) return <div>Erro: {error}</div>;

  return (
    <div className="container-ivd">
      <Header camara={camara} dados={dados} />
      <div>
        <label htmlFor="filter-date">Filtrar por data: </label>
        <input
          id="filter-date"
          type="date"
          value={filterDate}
          onChange={(event) => {
            const selectedDate = event.target.value;
            setFilterDate(selectedDate);
          }}
        />
      </div>

      <div className="container-ivd">
        <table className="container-table-ivd">
          <thead>
            <tr>
              <th>Amostra</th>
              <th>Tamanho do Dente</th>
              <th>Tamanho da Folha</th>
              <th>%IVD</th>
            </tr>
          </thead>
          <tbody>
            {filteredData
              .flatMap((entry, entryIndex) =>
                entry.diametros.map((diametro, index) => ({
                  entryIndex,
                  index,
                  tamanho_dente: diametro.tamanho_dente,
                  tamanho_folha: diametro.tamanho_folha,
                  ivd: (diametro.tamanho_folha / diametro.tamanho_dente) * 100,
                }))
              )
              .slice(0, 30) // Limita o número máximo de registros exibidos
              .map(
                ({ entryIndex, index, tamanho_dente, tamanho_folha, ivd }) => (
                  <tr key={`${entryIndex}-${index}`}>
                    <td>{index + 1}</td>
                    <td>{tamanho_dente}</td>
                    <td>{tamanho_folha}</td>
                    <td>{ivd.toFixed(2)}</td>
                  </tr>
                )
              )}
          </tbody>
        </table>
      </div>

      {/* Tabelinha de Estatísticas */}
      <h3>Estatísticas de IVD por Data</h3>
      <table>
        <thead>
          <tr>
            <th>Data</th>
            <th>Média (%)</th>
            <th>Máximo (%)</th>
            <th>Mínimo (%)</th>
            <th>Crescimento (%)</th>
          </tr>
        </thead>
        <tbody>
          {ivdStatsByDate.map((stat, index) => (
            <tr key={index}>
              <td>{stat.date}</td>
              <td>{stat.media}</td>
              <td>{stat.max}</td>
              <td>{stat.min}</td>
              <td>{stat.cresc}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Exibe a foto correspondente ao dia filtrado */}
      {photo ? (
        <div>
          <h3>Foto do Dia:</h3>
          <img
            src={`https://agropost.fazendamandaguari.com.br/public/fotos_anomalies/${photo}`}
            alt="Foto do IVD"
            className="photo-ivd"
          />
        </div>
      ) : (
        <div>Nenhuma foto disponível para a data selecionada.</div>
      )}

      {/* Gráfico */}
      <div>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={generateIvdForChart()}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="media"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey="max" stroke="#82ca9d" />
            <Line type="monotone" dataKey="min" stroke="#ff7300" />
            <Line
              type="monotone"
              dataKey="crescimento"
              stroke="#ff0000"
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Ivd;
